var limits_transformation = {"jaw":[1.0004955172733525, 1.1791555608881616],
    "mouthL":[0.5,0.6918937170476217],
    "mouthR":[0.5,0.6918937170476217],
    "mouthbottom":[0.3440858079888235,0.6],
    "nouthtop":[-1.3,-1.2],
    "eyelid1R":[1.3837874340952434,1.7146393667152604],
    "eyelid1L":[1.3837874340952434,1.7146393667152604],
    "eyelid2R":[1.6264121846832558,1.8028665487472648],
    "eyelid2L":[1.6264121846832558,1.8028665487472648],
    "eyelid3R":[1.4499578206192467,1.5381850026512514],
    "eyelid3L":[1.4499578206192467,1.5381850026512514],
    "eye1R":[1.472014616127248,1.5381850026512514],
    "eye1L":[1.472014616127248,1.5381850026512514],
    "eye2R":[1.4940714116352491,1.6484689801912569],
    "eye2L":[1.4940714116352491,1.6484689801912569],
    "eye3R":[1.472014616127248,1.5602417981592525],
    "eye3L":[1.472014616127248,1.5602417981592525],
    }
var limits_rotation = {
    "head":[
        {"vertical":[0.2984000274632953,-0.07507665574473408]},
        {"horizontal":[-0.3061481601022604,0.3061481601022604]}
    ],
    "jaw":[
        {"vertical":[1.577832072383284,1.9098199151633233]},
        {"horizontal":[-0.33261631471186176,0.33261631471186176]}
    ]
}
export {limits_transformation, limits_rotation};