export default{
  homepage:{
    heading: "Hey there! I'm Tejus",
    subHeading1: "I build cool stuff with code",
    subHeading2: "",
    credit: "© Handcrafted with Reactjs by Tejus Revi",
    srcText: "View source code",
    srcLink: "https://github.com/tejusrevi/personal-website"
  },
  about:{
    name: "Tejus Revi",
    pronoun: "He/Him",
    jobTitle: "Student at MUN",
    location: "St. John's, Canada",
    credit: "© Handcrafted with Reactjs by Tejus Revi",
    srcText: "View source code",
    srcLink: "https://github.com/tejusrevi/personal-website",
    image:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/tejus-revi-square.jpg?alt=media&token=e80173fb-dcd8-4695-b82b-432135314893"
  },
  skills:{
    languages: ["JavaScript", "Python", "Java", "PHP"],
    web: ["Django", "ReactJS", "NodeJS"],
    mobile: ["Android", "React Native"],
    data: ["numpy"],
    cloud: ["Firebase", "Digital Ocean"]
  },
  projects:[
    {
      name: "Workout Tracker (Academic Project)",
      id:"workout-tracker",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fworkout-tracker.mp4?alt=media&token=eecacfbd-4e53-4c76-ac33-0a6fb47002bc"
      },
      description: "Capstone project for COMP3100 Web Programming course at Memoria University. Workout Tracker is a full stack application that lets users track workout progress and body metrics. The application lets users create custom workout programs which can then be shared with community members. Uses local and Google oauth2.0 based authentication strategies.",
      tags: ["Web", "JavaScript", "Nodejs", "JQuery", "Passportjs", "HTML", "CSS", "MongoDB", "Google APIs"],
      links:
      [
        {name: "GitHub",
        href: "https://github.com/tejusrevi/workout-tracker"}
      ]
    },
    {
      name: "Mapogram",
      id:"mapogram",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fmapogram.mp4?alt=media&token=7dd3d7f8-f4fd-4f1b-a64f-caebf9d8a2ee"
      },
      description: "A React based project that lets users save their favorite locations and street views on the world map.",
      tags: ["Web", "Google Cloud Platform", "Firebase", "JavaScript", "ReactJS", "HTML", "CSS"],
      links:[
        {name: "GitHub",
        href: "https://github.com/tejusrevi/mapogram"},
        {name: "Website",
        href: "https://map-o-gram.web.app"}
      ]
    },
    {
      name: "Star Wars Title Crawl",
      id:"star-wars-title-crawl",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fstar-wars-title-crawl.mp4?alt=media&token=f7a62ca3-907e-4d00-bff1-0df012db5994"
      },
      description: "A threejs based project that render Star Wars opening title scene with user generated text.",
      tags: ["JavaScript", "Threejs", "Firebase", "ReactJS", "HTML", "CSS"],
      links:
      [
        {name: "GitHub",
        href: "https://github.com/tejusrevi/star-wars-title-crawl"},
        {name: "Website",
        href: "https://star-wars-title.web.app/"}
      ]
    },
    {
      name: "Morse Code Translator",
      id:"morse-code-translator",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fmorse-code-translator.mp4?alt=media&token=51535a27-5ebc-43fa-b1f0-56489c32a695"
      },
      description: "A web interface for translating English text into Morse Code using ITU standard. Provides visual and auditory output.",
      tags: ["JavaScript", "ReactJS", "Vantajs", "HTML", "CSS"],
      links:
      [
        {name: "GitHub",
        href: "https://github.com/tejusrevi/morse-code-translator"}
      ]
    },
    {
      name: "Password Book",
      id:"password-manager",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fpm.mp4?alt=media&token=806551a8-556e-482f-a56b-1bd8ad5e3a43"
      },
      description: "Desktop application that lets users save multiple passwords, all of which can be accessed using a master key. All paswords are stored in a text file in encrypted format.",
      tags: ["Python", "Tkinter", "Desktop"],
      links:
      [
        {name: "GitHub",
        href: "https://github.com/tejusrevi/password-manager"}
      ]
    },
    {
      name: "Movie Catalog + Recommendation Engine (Academic Project)",
      id:"movie-catalog",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fmovie-catalog.mp4?alt=media&token=d114b5ea-8c9a-49a5-8bb1-1256837eeaa3"
      },
      description: "Capstone project for CST8276 Advanced Database course done with a group of 3 members. The project involved sourcing movie data, building a web catalog and recommending similiar movies based on user selection.",
      tags: ["Django", "Python", "BS4", "MongoDB", "NoSQL", "Web", "PyMongo", "JSON", "Data Science", "HTML", "CSS"],
      links:
      [
        {name: "Demo",
        href: "https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/demo%2Fmovie-catalog-demo.mp4?alt=media&token=d457c88b-5f7b-4b0d-969a-26661a5f18d7"}
      ]
    },
    {
      name: "Guardian News App (Academic Project)",
      id:"guardian",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fguardian.mp4?alt=media&token=db0b8b2d-46dd-420d-a8d6-f07a9ea9fc31"
      },
      description: "Capstone project for CST2335 Mobile Graphics Programming course done with a group of 4 members. The project displays news articles collected using Guardian API, along with CRUD actions on the data.",
      tags: ["Mobile", "Android", "Java", "API"],
      links:
      [
        {name: "APK",
        href: "https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/downloadables%2Fapp-release.apk?alt=media&token=d0d54c3c-027c-42d7-b6e1-fbaf6c223630"}
      ]
    },
    {
      name: "Interactive Head",
      id:"interactive-head",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Finteractive-head.mp4?alt=media&token=9ee8b24b-1b2d-449c-b9b6-60bdc9fd40e3"
      },
      description: "A threejs based project that render a 3D model of human head onto an HTML canvas. The model is controlled real-time based on cursor position or gyroscopic movements.",
      tags: ["3D Modeling", "JavaScript", "Threejs", "ReactJS", "HTML"],
      links:[
        {name: "GitHub",
        href: "https://github.com/tejusrevi/interactive-human-face"},
        {name: "Website",
        href: "https://interactive-head.web.app/"}
      ]
    },
  ],
  tags:[
    {
      name: "Web",
      type: "topic"
    },
    {
      name: "Desktop",
      type: "topic"
    },
    {
      name: "Data Science",
      type: "topic"
    },
    {
      name: "Mobile",
      type: "topic"
    },
    {
      name: "JavaScript",
      type: "language"
    },
    {
      name: "Python",
      type: "language"
    },
    {
      name: "Java",
      type: "language"
    },
    {
      name: "ReactJS",
      type: "framework"
    },
    {
      name: "Django",
      type: "framework"
    },
    {
      name: "Nodejs",
      type: "framework"
    },
    {
      name: "JQuery",
      type: "framework"
    },
    {
      name: "Threejs",
      type: "library"
    },
    {
      name: "MongoDB",
      type: "database"
    },
    {
      name: "Firebase",
      type: "platform"
    },
    {
      name: "Google Cloud Platform",
      type: "platform"
    },
    {
      name: "HTML",
      type: "format"
    },
    {
      name: "CSS",
      type: "format"
    },
    {
      name: "NoSQL",
      type: "format"
    },
  ]
}